<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <path
                d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z"
                :fill="color"
            />
        </template>
    </AppIcon>
</template>
